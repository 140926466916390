import './selecionarPrecificador.css';
import { useState,useEffect,useContext} from 'react';
import {IoClose} from 'react-icons/io5';
import {AuthContext} from '../../contexts/auth';
import api, { apiUrl } from '../../services/api';
import {toast} from 'react-toastify';
import InputPesquisa from '../InputPesquisa';
import Alcool from '../../img/alcool.png';

export default function SelecionarPrecificador({pedido,formato,campanha,fechar}){

    //CATEGORIA
    const [categoriaSelecionada,setCategoriaSelecionada] = useState('0');
    const [categoria,setCategoria] = useState([]);
    
    //Lista de produtos
    const [listaP,setListaP] = useState([])
    const [listaPFiltrada, setListaPFiltrada] = useState([]);
    
    //ITENS SELECIONADOS
    const [selecionados,setSelecionados] = useState([]);
    const handleSelect = function(selectedItems) {
        const select = [];
        for (let i=0; i<selectedItems.length; i++) {
            select.push(selectedItems[i].value);
        }
        setSelecionados(select);
    }

    
    //ITENS DO PEDIDO
    const [produtosP, setProdutosP] = useState('');
    
    const [precoNovo,setPrecoNovo] = useState('')
    const [precoNovoPromocional, setPrecoNovoPromocional] = useState("")

    //Numero de paginas
    const [paginas,setPaginas] = useState(0);

    //Loja
    const [jLoja,setJloja] = useState(JSON.parse(localStorage.getItem('dataLoja')));
    const [load,setLoad] = useState(false);
    const {user,signOut} = useContext(AuthContext);

    const [loadingPdf, setLoadingPdf] = useState(false)
    const [gerarPdf, setGerarPdf] = useState(true)

    //BUSCA PRODUTOS DO VAREJISTA
    async function getProdutos(){
        if(categoriaSelecionada !== '0'){
            setLoad(true)
            await api.get(`/produtos/categoria/${categoriaSelecionada}`,
                {
                    headers:{'Authorization':user.token},
                    params:{
                        varejo:jLoja.varejo,
                        preco:jLoja.preco,
                        campanhaId: pedido.campanhaId
                    }
                }
            ).then((resp)=>{
                setListaP(resp.data.produtos)
                setListaPFiltrada(resp.data.produtos)
                setLoad(false)
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    setListaP([])
                    setListaPFiltrada([])
                    toast.error(error.response.data.error)
                    setLoad(false)
                }
            })
        }else{
            setLoad(true)
            await api.get('/produtos/varejo',
                {
                    headers:{'Authorization':user.token},
                    params:{
                        varejo:jLoja.varejo,
                        preco:jLoja.preco,
                        campanhaId: campanha ? campanha.id : 0
                    }
                },
            ).then((resp)=>{
                setListaP(resp.data.produtos)
                setListaPFiltrada(resp.data.produtos)
                setLoad(false)
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    setListaP([])
                    setListaPFiltrada([])
                    toast.error(error.response.data.error)
                    setLoad(false)
                }
            })
        }
    }

    const buscaProdutos = async () => {
        setGerarPdf(true)
        try {
            const resp = await api.get(`/produtos_pedido/${pedido.id}`,
                {
                    headers:{'Authorization':user.token},
                    params: {
                        varejo:jLoja.varejo,
                        preco:jLoja.preco,
                        campanhaId: pedido.campanhaId
                    }
                }
            )
            
            setProdutosP(resp.data.produtos)
            setPaginas(resp.data.quantidade)
            setLoad(false)
        }
        catch (error) {
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.info(error.response.data.error)
                setProdutosP('')
                setLoad(false)
            }
        }
    }

    useEffect(()=>{
        setJloja(JSON.parse(localStorage.getItem('dataLoja')))
        //BUSCA CATEGORIAS
        async function getCategorias(){
            setLoad(true)
            await api.get('/categorias',
                {
                    headers:{'Authorization':user.token}
                }
            ).then((resp)=>{
                let json = resp.data
                setCategoria(json.categorias.map((v,k)=>{
                    return <option key={k} value={v.id}>{v.categoria}</option>
                }))
                setLoad(false)
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoad(false)
                }
            })
        }
        buscaProdutos()
        getCategorias()
    },[user.token,signOut])

    useEffect(() => {
        if (pedido?.id) getProdutos()
    }, [pedido,user.token,signOut,categoriaSelecionada])

    async function criaPDF(){
        let retornarToast = true
        if(loadingPdf){
            return
        }

        if(!gerarPdf){
            return window.open(`${apiUrl}/uploads/ID${pedido.id}.pdf`, "_blank", "noreferrer");
        }

        setLoadingPdf(true)
        if(produtosP !== ''){
            const itensPedido = []
            for(let i=0;i<produtosP.length;i++){

                if(formato == 8 && (produtosP[i].descricao.length > 27 || produtosP[i].descricao2.length > 30)){
                    setLoadingPdf(false)
                    return toast.warning(
                        <>
                          {produtosP[i].descricao} limite de caracteres maior que o permitido
                          <br />
                          Descrição 1: 27 caracteres
                          <br />
                          Descrição 2: 30 caracteres
                        </>
                      );
                }
                
                // if((formato == 6 || formato == 7) && Number(produtosP[i].preco_regular) <= 0){
                //     setLoadingPdf(false)
                //     return toast.warning(<>
                //         {produtosP[i].descricao} 
                //         <bold> sem preço regular</bold> cadastrado, favor cadastre valor nesse campo
                //         </>
                //       )
                // }

                // if(((formato == 7) && ((produtosP[i].descricao.length + produtosP[i].descricao2.length) > 50))){
                //     setLoadingPdf(false)
                //     return toast.warning(
                //         <>
                //           {produtosP[i].descricao} limite de caracteres maior que o permitido
                //           <br />
                //           Descrição 1: 36 caracteres
                //           <br />
                //           Descrição 2: 36 caracteres
                //         </>
                //       );
                // }

                if(((formato == 6) && (produtosP[i].descricao.length > 36 || produtosP[i]?.descricao2?.length > 36))){
                    setLoadingPdf(false)
                    return toast.warning(
                        <>
                          {produtosP[i].descricao} limite de caracteres maior que o permitido
                          <br />
                          Descrição 1: 36 caracteres
                          <br />
                          Descrição 2: 36 caracteres
                        </>
                      );
                }

                if(produtosP[i].preco > 0){
                    itensPedido.push({
                        descricao:produtosP[i].descricao,
                        descricao2:produtosP[i].descricao2,
                        preco:String(produtosP[i].preco),
                        preco_promocional:String(produtosP[i].preco_promocional),
                        preco_regular: String(produtosP[i].preco_regular),
                        peso:produtosP[i].peso,
                        codigo:produtosP[i].codigo,
                        frase_promocional: produtosP[i].frase_promocional,
                        unidade_de_medida_promocional: produtosP[i].unidade_de_medida_promocional,
                        alcoolico: produtosP[i].alcoolico,
                        categoria:produtosP[i].categoria
                    })
                }
                else if(retornarToast){
                    retornarToast = false
                    toast.info('CONTÉM ITENS SEM PREÇO PARA A SUA LOJA.')
                }
            }
            await api.post('/pdf/precificador',
                {
                    listaProdutos:itensPedido,
                    pedido_id:pedido.id,
                    formato:formato,
                    loja:jLoja.nome,
                    nome:jLoja.liderSenior,
                    contato:jLoja.telefoneS,
                    endereco:jLoja.endereco,
                    bairro:jLoja.bairro,
                    cep:jLoja.cep,
                    cidade:jLoja.cidade,
                    estado:jLoja.estado,
                },
                {
                    headers:{'Authorization':user.token}
                }
            ).then((resp)=>{
                setLoad(false)
                setTimeout(() => {
                    window.open(`${apiUrl}/uploads/ID${pedido.id}.pdf`, "_blank", "noreferrer");
                    setLoadingPdf(false)
                    setGerarPdf(false)
                }, 3000)
            }).catch((error)=>{
                setLoadingPdf(false)
                if(error.response?.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.info(error.response.data.error)
                    setLoad(false)
                }
            })
        }
    }

    //ADICIONA iTEM AO PEDIDO
    async function adicionaItem(){
        setGerarPdf(true)
        if(selecionados.length !== 0){
            setLoad(true)
            let itens = []
            for(let i=0;i < selecionados.length;i++){
                let filtroProduto = listaP.filter(produto => produto.id == selecionados[i])
                itens.push({
                    id_produto: selecionados[i],
                    precoOficial:filtroProduto[0].preco,
                    precoRegular: filtroProduto[0]?.preco_regular,
                    id_pedido:pedido.id
                })
            }
            //Adiciona itens
            await api.post('/produtos_pedido',
                {
                    relacionamentos:itens
                },
                {
                    headers:{'Authorization': user.token}
                }
            ).then((resp)=>{
                toast.success(resp.data.mensagem)
                setSelecionados([])
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.info(error.response.data.error)
                    setLoad(false)
                }
            })

            await buscaProdutos();
        }else{
            toast.info('Nenhum item selecionado')
        }
        setLoad(false)   
    }

    //altera preco executado
    async function alteraPrecoExecutado(itemId,itemPreco,precoPromocional){
            setGerarPdf(true)
            setLoad(true)
            await api.patch(`/produtos_pedido/${itemId}`,
                {
                    preco:itemPreco,
                    preco_promocional: precoPromocional
                },
                {
                  headers:{'Authorization':user.token}
                }
            ).then((resp)=>{
                toast.success(resp.data.mensagem)
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.info(error.response.data.error)
                    setLoad(false)
                }
            })
            //busca itens do pedido
            await api.get(`/produtos_pedido/${pedido.id}`,
                {
                    headers:{'Authorization':user.token}
                }
            )
            .then((resp)=>{
                if(resp.data.produtos.length === 0){
                    setProdutosP('')
                    setPaginas(resp.data.quantidade)
                    setLoad(false)
                }else{
                    setProdutosP(resp.data.produtos)
                    setPrecoNovo('')
                    setPrecoNovoPromocional('')
                    setPaginas(resp.data.quantidade)
                    setLoad(false)
                }
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.info(error.response.data.error)
                    setProdutosP('')
                    setLoad(false)
                }
            })
            setLoad(false)
    }
    //Remove item do pedido
    async function removeItem(itemId){
        setGerarPdf(true)
        setLoad(true)
        await api.delete('/produtos_pedido',
            {
            params:
            {
                produto_id:itemId,
                pedido_id:pedido.id
            },
            headers:{'Authorization':user.token}
            }
        ).then((resp)=>{
            toast.success(resp.data.mensagem)
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.info(error.response.data.error)
                setLoad(false)
            }
        })
        await buscaProdutos();
        setLoad(false)
    }

    if(load === true || loadingPdf === true){
        return(
            <div className='modalPrecificador'>
                <div className='modalPrecificador-container'>
                    <h1>Carregando...</h1>
                </div>
            </div>
        )
    }

    function filtroDeProdutos(lista, valor){
        return lista.filter((produto) => produto.produto.toUpperCase().includes(valor.toUpperCase()));
    }

    return(
        <div className='modalPrecificador'>
            <div className='modalPrecificador-fechar'>
                <button onClick={fechar} className='modalPrecificador-btn-fechar'>
                    <IoClose size={30} color='#FFF'/>
                </button>
            </div>
            <div className='modalPrecificador-container'>
                <div>
                    <h1>Adicionar Itens</h1>
                    <div className='modalPrecificador-componentes'>
                        <div className='adicionar-itens-header'>
                            <div className='pesquisar-container'>
                                <h3>Pesquisar</h3>
                                <div className='input-pesquisa-container'>
                                    <InputPesquisa lista={listaP} setLista={setListaPFiltrada} funcaoDeFiltro={filtroDeProdutos}/>
                                </div>
                            </div>
                            <div className='sel-categoria-container'>
                                <h3>Categoria</h3>
                                <select className='sel-categoria' value={categoriaSelecionada} multiple={false} onChange={(e)=>{setCategoriaSelecionada(e.target.value)}}>
                                    <option key={0} value={0}>SELECIONAR</option>
                                    {categoria}
                                </select>
                            </div>
                        </div>
                        <div className='sel-produto-container'>
                            <h3>Selecione os Produtos</h3>
                            <select className='sel-produto' value={selecionados} size='5' multiple={true} onChange={(e)=>{handleSelect(e.target.selectedOptions)}}>
                                {listaPFiltrada.map((produto)=>{
                                    return <option key={produto.id} value={produto.id}>{produto.produto}</option>
                                })}
                            </select>
                        </div>
                        <div className='modalPrecificador-button'>
                            <button onClick={adicionaItem}>ADICIONAR</button>
                            <button onClick={()=>setSelecionados([])}>LIMPAR</button>
                        </div>
                        <div className='modalPrecificador-button1'>
                            <a onClick={ () => 
                            {
                                criaPDF()
                            }}>DOWNLOAD PDF</a>
                        </div>
                    </div>
                </div>
                <div className='modalPrecificador-preview'>
                    <h1>Itens Selecionados</h1>
                    {produtosP !== '' ? 
                     (
                        <div className='modalPrecificador-preview-itens'>
                            {produtosP.map((item)=>{
                                if(item.preco > 0){
                                    item.preco = Number(item.preco).toFixed(2)
                                    item.preco_promocional = Number(item.preco_promocional).toFixed(2)
                                    item.preco_regular = Number(item.preco_regular).toFixed(2)
                                    const prod = listaP.find((produto) => produto.id == item.produtoId);

                                    return(
                                        <div
                                            className='modalPrecificador-preview-container'
                                            key={item.id}
                                        >
                                            <div className='modalPrecificador-preview-areaItem'>
                                                <h1>{item.descricao}</h1>
                                                <h2>{item.descricao2}</h2>
                                                {(formato == 6 || formato == 7)  && <h4 className='frase_promocional'>A PARTIR DE 2N</h4>}
                                                {formato == 8 && <h4 className='frase_promocional'>O KG SAI POR:</h4>}
                                                {item.alcoolico == 1 && <img className='alcool' src={Alcool} />}
                                                <div className={`item-baixo ${formato == 4 || formato == 5 ? " diminuir" : ""}`}>
                                                    {formato == 4 || formato == 5 ? <h2>De:</h2> : <></>}
                                                    <h3 >{item.preco.toString().slice(0,-3)},</h3>
                                                    <h4>{item.preco.toString().slice(-2)}</h4>
                                                    {formato == 4 || formato == 5 ? <h2>Por:</h2> : <></>}
                                                    {formato == 4 || formato == 5 ? <h3>{item.preco_promocional}</h3> : <></>}
                                                </div>
                                                <div className={`textPeso ${formato == 4 || formato == 5 ? " right" : ""}`}>
                                                </div>
                                            </div>
                                            {!!prod?.activeEditPrice && (
                                                <div className='alteraPreco'>
                                                    <h3>ALTERAR PREÇO</h3>
                                                    {formato == 5 || formato == 4 ? <h5 className='label'>De:</h5> : <></>}
                                                    <input className='inputAlterarPreco' type={'number'} placeholder={'Ex: 89,99'} value={precoNovo}  onChange={(e) => setPrecoNovo(e.target.value)}/>
                                                    {formato == 5 || formato == 4 ? <h5 className='label'>Por:</h5> : <></>}
                                                    {formato == 5 || formato == 4 ? <input className='inputAlterarPreco' type={'number'} placeholder={'Ex: 89,99'} value={precoNovoPromocional}  onChange={(e) => setPrecoNovoPromocional(e.target.value)}/> : <></>}
                                                </div>
                                            )}
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                {!!prod?.activeEditPrice && (
                                                    <button className='modalPrecificador-preview-container-btn' onClick={()=>alteraPrecoExecutado(item.id,precoNovo,precoNovoPromocional)}>SALVAR</button>
                                                )}
                                                <button className='modalPrecificador-preview-container-btn' onClick={()=>removeItem(item.id)}>REMOVER</button>
                                            </div>
                                        </div>
                                    )
                                }
                                else{ 
                                    return(
                                        <div className='modalPrecificador-preview-container'>
                                            <div key={item.id} className='modalPrecificador-preview-areaItem'>
                                                <h1>{item.descricao}</h1>
                                                <h2>{item.descricao2}</h2>
                                                <div className='item-baixo'>
                                                    <h3>SEM PREÇO</h3>
                                                </div>
                                            </div>
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <button className='modalPrecificador-preview-container-btn' onClick={()=>removeItem(item.id)}>REMOVER</button>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                    ) : <div className='container-vazio'>
                            <h1>Nenhum Item Adicionado</h1>
                        </div> 
                }
                </div>
            </div>
        </div>
    )
}
import styled from 'styled-components';

export const SaveButton = styled.button`
    background-color: #29C013;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    margin: 30px auto 0px;
`;

export const Container = styled.div`
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 60dvh;
    width: 90%;

    .modalPrecificador-fechar{
        position: absolute;
        top: -30px;
        right: -30px;
        background-color: red;
        border-radius: 30px;
    }

    .flex{
        display: flex;
        margin-top: 30px;
        width: 90%;
        justify-content: space-around;

        .preco{
            display: flex;
            flex-direction: column;
            margin-bottom: 14px;
        }
        
        input{
            padding: 6px;
            border-radius: 4px;
        }

        .titulo{
            text-align: start;
        }

        .legenda{
            font-size: 12px;
        }
    }

    > .lista_precos {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 20px 10px;

        > .preco {
            display:flex;
            flex-direction:column;
            width:250px;
            margin-bottom:10px;

            > label.titulo {
                font-size: 20px;
                margin-right:5px;
                margin-bottom:5px;
                font-weight:bold ;
            }

            > label.legenda {
                font-size: 18px;
                margin-top: 5px;
            }

            > input {
                font-size: 20px;
                border-radius:9px;
                margin-right:5px;
                padding-left:5px;
            }
        }
    }
`;
